import { assertActivity, computeDefaultShares } from '@orus.eu/activity'
import { type ActivitiesDistribution } from '@orus.eu/dimensions'
import { type Activity } from '@orus.eu/pharaoh/src/components/features/rcda'

type RawActivity = {
  activity: string
  displayName: string
}

export function mapActivitiesToDistribution(
  activitiesRaw: RawActivity[],
  activitiesDistribution: ActivitiesDistribution | undefined,
): Activity[] {
  const everyExistingActivityHasShare =
    activitiesDistribution?.type === 'multiple' &&
    activitiesRaw.every((activity) => activitiesDistribution.distribution[activity.activity]) &&
    activitiesRaw.length === Object.keys(activitiesDistribution.distribution).length

  const activitiesDistributionDefaultShare = computeDefaultShares(
    activitiesRaw.map(({ activity }) => assertActivity(activity)),
    activitiesDistribution,
  )

  return activitiesDistributionDefaultShare.map(({ id, share }) => ({
    id: assertActivity(id),
    name: activitiesRaw.find(({ activity }) => activity === id)?.displayName || '',
    sharePercentage:
      activitiesDistribution?.type === 'multiple' &&
      activitiesDistribution.distribution[id] &&
      everyExistingActivityHasShare
        ? activitiesDistribution.distribution[id].sharePercentage
        : Math.floor(share * 100),
  }))
}

export function computeIsValid(activities: Pick<Activity, 'sharePercentage'>[]): boolean {
  return activities.reduce((acc, activity) => acc + activity.sharePercentage, 0) === 100
}
