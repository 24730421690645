import type { IntegerSubscriptionUiElement } from '@orus.eu/dimensions'
import type { ValidatedTypeMapper } from '@orus.eu/pharaoh'
import { failure, success } from '@orus.eu/result'
import { validateInteger, validatePositiveInteger } from '../../../../lib/validation'

const integerMapper: ValidatedTypeMapper<number> = {
  inputType: 'number',
  format: (value) => value.toString(),
  formatPlaceholder: (value) => value.toString(),
  parse: (text, language) => {
    const result = validateInteger(text, language)
    return result.ok ? success(result.value) : failure(result.hint)
  },
}

const positiveIntegerMapper: ValidatedTypeMapper<number> = {
  inputType: 'number',
  format: (value) => value.toString(),
  formatPlaceholder: (value) => value.toString(),
  parse: (text, language) => {
    const result = validatePositiveInteger(text, language)
    return result.ok ? success(result.value) : failure(result.hint)
  },
}

export const mappers: Record<IntegerSubscriptionUiElement['type'], ValidatedTypeMapper<number>> = {
  integer: integerMapper,
  'positive-integer': positiveIntegerMapper,
}
